<template>
    <default-template
        :title="title"
        :is-visible-public="true"
        :is-visible-logout="true"
        :is-visible-pass-update="true"
        :is-visible-jigyosya-login="false"
        :is-house="true"
        :is-visible-user-header="true"
    >
        <div class="bl_contents_wrapper">
            <div class="bl_underHeader ly_underHeader">
                <h2 class="el_lv2Heading">機器{{applyName}}</h2>
                <hr class="hp_flexspacer"/>
                <p>以下の機器を{{applyName}}します。よろしいですか？</p>
            </div>
            <error-list />
            <device-confirm />
            <device-cancel-form v-if="isCancel" />
            <div class="ly_buttonBox">
                <app-button
                    name="キャンセル"
                    @click="back"
                    class="cancel"
                    ref="jest-device-back"
                />
                <app-button
                    v-if="isPublish"
                    name="成績証明書又は自己適合宣言書を確認"
                    @click="showCertificate"
                    ref="jest-device-show-certificate"
                />
                <app-button
                    v-if="isDelete"
                    name="削除"
                    @click="deleteSubmit"
                    ref="jest-device-delete-submit"
                    class="delete"
                />
                <app-button
                    v-if="isStatusPublished"
                    name="公開申請"
                    @click="publishSubmit"
                    ref="jest-device-publish-submit"
                />
                <app-button
                    v-if="isStatusPublishedCancel"
                    name="取下申請"
                    @click="publishCancelSubmit"
                    ref="jest-device-publish-cancel-submit"
                />
                <app-button
                    v-if="isStatusCanceled"
                    name="取下"
                    @click="cancelSubmit"
                    ref="jest-device-cancel-submit"
                />
            </div>
            <div
                id="invisible"
                v-show="false"
            />
        </div>
    </default-template>
</template>

<script>
/**
 * 機器の申請画面のview
 *
 * [算出プロパティ]
 * isDelete...削除画面か否かを返す
 * isStatusPublished...公開申請画面か否かを返す
 * isStatusPublishedCancel...取下申請画面か否かを返す
 * isPublish...公開申請画面または取下申請画面か否かを返す
 * isStatusCanceled...取下画面か否かを返す
 * isCancel...取下申請画面または取下画面か否かを返す
 * applyName...画面が扱う操作の日本語名を返す
 * title...ページタイトルを返す(機器日本語名+操作日本語名)
 * 
 * [イベント処理]
 * back...対応する機器一覧に戻る
 * delete...機器に応じた削除処理
 * deleteSubmit...機器に応じた削除処理を実行し、成功時には機器一覧に遷移、失敗時にはエラー表示
 * publish...機器に応じた公開申請処理
 * publishSubmit...機器に応じた公開申請処理を実行し、成功時には機器一覧に遷移、失敗時にはエラー表示
 * publishCancel...機器に応じた取下申請処理
 * publishCancelSubmit...機器に応じた取下申請処理を実行し、成功時には機器一覧に遷移、失敗時にはエラー表示
 * getFile...機器に応じた機器証明書の取得処理
 * showCertificate...機器に応じた機器証明書を表示
 * 
 */
import ErrorList from '@/components/molecules/ErrorList'
import DeviceCancelForm from '@/components/organisms/DeviceCancelForm.vue'
import DeviceConfirm from '@/components/organisms/DeviceConfirm.vue'
import { mapActions, mapGetters } from 'vuex'
import { move } from '@/util'
import Pdf from "@/mixins/pdf"
import Progress from '@/mixins/progress.js'
import device from '@/mixins/device'

export default {
    name:'OrganizationDeviceApply',
    components:{
        ErrorList,
        DeviceCancelForm,
        DeviceConfirm,
    },
    mixins:[Progress, Pdf, device],
    computed:{
        ...mapGetters({
            cogenerationDetail:'cogenerations/cogenerationDetail',
            hybridWaterHeatersDetail:'hybridWaterHeaters/hybridWaterHeatersDetail',
            electricHeatPumpsDetail:'electricHeatPumps/electricHeatPumpsDetail',
            solarSystemDetail:'solarSystems/solarSystemDetail',
            solarWaterHeaterDetail:'solarWaterHeaters/solarWaterHeaterDetail',
        }),
        /**
         * 機器詳細を返す
         */
        deviceDetail(){
            return {
                cogenerations:this.cogenerationDetail,
                hybridWaterHeaters:this.hybridWaterHeatersDetail,
                electricHeatPumps:this.electricHeatPumpsDetail,
                solarSystems:this.solarSystemDetail,
                solarWaterHeaters:this.solarWaterHeaterDetail,
            }[this.$route.meta.deviceCategoryKey]
        },
        /**
         * 機器の証明書IDを返す
         */
        deviceCertificateId(){
            return {
                cogenerations:this.cogenerationDetail.cogeneration_system_certificate_id,
                hybridWaterHeaters:this.hybridWaterHeatersDetail.hybrid_water_heater_certificate_id,
                electricHeatPumps:this.electricHeatPumpsDetail.electric_heat_pump_certificate_id,
                solarSystems:this.solarSystemDetail.solar_system_certificate_id,
                solarWaterHeaters:this.solarWaterHeaterDetail.solar_water_heater_certificate_id,
            }[this.$route.meta.deviceCategoryKey]
        },
        /**
         * 削除画面か否かを返す
         */
        isDelete(){
            return this.$route.path.includes('/delete')
        },
        /**
         * 公開申請画面か否かを返す
         * (includesで判定すると取下申請が含まれてしまうので注意)
         */
        isStatusPublished(){
            return this.$route.path.endsWith('/status/published')
        },
        /**
         * 取下申請画面か否かを返す
         */
        isStatusPublishedCancel(){
            return this.$route.path.includes('/status/published/cancel')
        },
        /**
         * 公開申請画面または取下申請画面か否かを返す
         */
        isPublish(){
            return this.$route.path.includes('/status/published')
        },
        /**
         * 取下画面か否かを返す
         */
        isStatusCanceled(){
            return this.$route.path.includes('/status/canceled')
        },
        /**
         * 取下申請画面または取下画面か否かを返す
         */
        isCancel(){
            return this.isStatusPublishedCancel || this.isStatusCanceled
        },
        /**
         * 画面が扱う操作の日本語名を返す
         */
        applyName(){
            if (this.isDelete) {
                return '削除'
            } else if (this.isStatusPublishedCancel) {
                return '取下申請'
            } else if (this.isStatusPublished) {
                return '公開申請'
            } else if (this.isStatusCanceled) {
                return '取下'
            } else {
                return ''
            }
        },
        /**
         * ページタイトルを返す(機器日本語名+操作日本語名)
         */
        title() {
            return this.deviceCategoryName + this.applyName
        }
    },
    mounted(){
        this.clearError()
    },
    methods:{
        ...mapActions({
            clearError:'error/clear',
            updateError:'error/update',
        }),
        /**
         * 対応する機器一覧に戻る
         */
        back(){
            const path =  {
                cogenerations:'/organization/cogeneration_systems',
                hybridWaterHeaters:'/organization/hybrid_water_heaters',
                electricHeatPumps:'/organization/electric_heat_pumps',
                solarSystems:'/organization/solar_systems',
                solarWaterHeaters:'/organization/solar_water_heaters',
            }[this.$route.meta.deviceCategoryKey]
            move(this.$router, path)
        },
        /**
         * 機器に応じた削除処理
         */
        delete(){
            return this.$store.dispatch(this.$route.meta.deviceCategoryKey + '/deleteDevice')
        },
        /**
         * 機器に応じた削除処理を実行し、成功時には機器一覧に遷移、失敗時にはエラー表示
         */
        deleteSubmit(){
            this.clearError()
            return this.delete()
                .then(response => {
                    if (response.status == 200) {
                        this.updateError(response)
                        this.back()
                    } else {
                        this.updateError(response)
                    }
                })
        },
        /**
         * 機器に応じた公開申請処理
         */
        publish(){
            return this.$store.dispatch(this.$route.meta.deviceCategoryKey + '/publishDevice')
        },
        /**
         * 機器に応じた公開申請処理を実行し、成功時には機器一覧に遷移、失敗時にはエラー表示
         */
        publishSubmit(){
            this.clearError()
            return this.publish()
                .then(response => {
                    if (response.status == 200) {
                        this.updateError(response)
                        this.back()
                    } else {
                        this.updateError(response)
                    }
                })
        },
        /**
         * 機器に応じた取下申請処理
         */
        publishCancel(){
            return this.$store.dispatch(this.$route.meta.deviceCategoryKey + '/publishCancelDevice')
        },
        /**
         * 機器に応じた取下申請処理を実行し、成功時には機器一覧に遷移、失敗時にはエラー表示
         */
        publishCancelSubmit(){
            this.clearError()
            return this.publishCancel()
                .then(response => {
                    if (response.status == 200) {
                        this.updateError(response)
                        this.back()
                    } else {
                        this.updateError(response)
                    }
                })
        },
        /**
         * 機器に応じた取下処理
         */
        cancel(){
            return this.$store.dispatch(this.$route.meta.deviceCategoryKey + '/cancelDevice')
        },
        /**
         * 機器に応じた取下処理を実行し、成功時には機器一覧に遷移、失敗時にはエラー表示
         */
        cancelSubmit(){
            this.clearError()
            return this.cancel()
                .then(response => {
                    if (response.status == 200) {
                        this.updateError(response)
                        this.back()
                    } else {
                        this.updateError(response)
                    }
                })
        },
        /**
         * 機器に応じた機器証明書の取得処理
         */
        getFile(){
            return this.$store.dispatch(this.$route.meta.deviceCategoryKey + '/getDeviceCertificateFile', this.deviceCertificateId)
        },
        /**
         * 機器に応じた機器証明書を表示
         */
        async showCertificate(){
            const file = await this.getFile()
            this.openPdf(file, this.$route.meta.deviceCategoryKey)
        }
    }
}
</script>

<style scoped>
.uq_deviceCancelForm,
.uq_deviceConfirm{
    width: 100%;
    min-width: 800px;
}
.uq_deviceConfirm+.uq_deviceCancelForm{
    margin-top: -0.5px;
}
</style>