<template>
    <div class="boxcontainer ly_form_box uq_deviceCancelForm">
        <p>取下の理由を記載してください。取下後、備考欄に記載されます。</p>
        <app-text
            name="canceled_at"
            v-model="canceled_at"
            :lbl_required="true"
            label="取下年月日"
            class="formrow"
            type="date"
            ref="jest-device-canceled-at"
        />
        <div class="formrow">
            <app-label
                name="reason"
                label="取下の原因となったユニット"
                class="lbl_required"
            />
            <div>
                <app-checkbox
                    v-if="isCogenerations"
                    name="reason"
                    v-model="reason"
                    ref="jest-device-cancel-checkbox0"
                    value="発電"
                    label="発電"
                />
                <app-checkbox
                    v-if="isHybridWaterHeaters"
                    name="reason"
                    v-model="reason"
                    ref="jest-device-cancel-checkbox0"
                    value="ヒートポンプ"
                    label="ヒートポンプ"
                />
                <app-checkbox
                    v-if="isTank"
                    name="reason"
                    v-model="reason"
                    value="貯湯"
                    label="貯湯"
                />
                <app-checkbox
                    v-if="isTank"
                    name="reason"
                    v-model="reason"
                    value="補助熱源"
                    label="補助熱源"
                />
                <app-checkbox
                    v-if="isElectricHeatPumps"
                    name="reason"
                    v-model="reason"
                    ref="jest-device-cancel-checkbox0"
                    value="システム形式"
                    label="システム形式"
                />
                <app-checkbox
                    v-if="isElectricHeatPumps"
                    name="reason"
                    v-model="reason"
                    value="ヒートポンプユニット形式"
                    label="ヒートポンプユニット形式"
                />
                <app-checkbox
                    v-if="isElectricHeatPumps"
                    name="reason"
                    v-model="reason"
                    value="貯湯ユニット形式"
                    label="貯湯ユニット形式"
                />
                <app-checkbox
                    v-if="isSolar"
                    name="reason"
                    v-model="reason"
                    ref="jest-device-cancel-checkbox0"
                    value="システム型式"
                    label="システム型式"
                />
                <app-checkbox
                    v-if="isSolarSystem"
                    name="reason"
                    v-model="reason"
                    value="集熱器型式"
                    label="集熱器型式"
                />
                <app-checkbox
                    v-if="isSolarSystem"
                    name="reason"
                    v-model="reason"
                    value="蓄熱槽型式"
                    label="蓄熱槽型式"
                />
            </div>
        </div>
        <app-text
            name="note"
            v-model="note"
            :lbl_required="true"
            label="理由"
            class="formrow uq_deviceCancelReason"
            type="text"
            ref="jest-device-canceled-note"
        />
    </div>
</template>

<script>
/**
 * 取下時必要事項記入フォーム
 * 
 * [算出プロパティ]
 * 
 * [イベント処理]
 */
import { mapGetters } from 'vuex'
import { mapComputedProperties, mapComputedArrayProperties } from '@/util'
import device from '@/mixins/device'

export default {
    name:'DeviceCancelForm',
    mixins:[device],
    computed:{
        ...mapGetters({
            cogenerationsCancelReason:'cogenerations/cancelReason',
            hybridWaterHeatersCancelReason:'hybridWaterHeaters/cancelReason',
            electricHeatPumpsCancelReason:'electricHeatPumps/cancelReason',
            solarSystemsCancelReason:'solarSystems/cancelReason',
            solarWaterHeatersCancelReason:'solarWaterHeaters/cancelReason',
        }),
        ...mapComputedProperties([
            'canceled_at',
            'note'
        ]),
        ...mapComputedArrayProperties([
            'reason'
        ]),
        /**
         * 送信用のデータを書き換えられるように取得する
         */
        data(){
            // 電気ヒートポンプはゲッター実装待ち
            return {
                cogenerations:this.cogenerationsCancelReason,
                hybridWaterHeaters:this.hybridWaterHeatersCancelReason,
                electricHeatPumps:this.electricHeatPumpsCancelReason,
                solarSystems:this.solarSystemsCancelReason,
                solarWaterHeaters:this.solarWaterHeatersCancelReason,
            }[this.$route.meta.deviceCategoryKey]
        },
        isCogenerations(){
            return this.$route.meta.deviceCategoryKey === 'cogenerations'
        },
        isHybridWaterHeaters(){
            return this.$route.meta.deviceCategoryKey === 'hybridWaterHeaters'
        },
        isElectricHeatPumps(){
            return this.$route.meta.deviceCategoryKey === 'electricHeatPumps'
        },
        isSolarSystem(){
            return this.$route.meta.deviceCategoryKey === 'solarSystems'
        },
        isSolarWaterHeater(){
            return this.$route.meta.deviceCategoryKey === 'solarWaterHeaters'
        },
        isSolar(){
            return this.isSolarSystem || this.isSolarWaterHeater
        },
        isTank(){
            return this.isCogenerations || this.isHybridWaterHeaters
        },
    },
    mounted(){
        this.update({
            canceled_at:null,
            reason:[],
            note:'',
        })
    },
    methods:{
        update(values){
            return this.$store.dispatch(this.$route.meta.deviceCategoryKey + '/update', values)
        },
    }
}
</script>

<style scoped>
.uq_deviceCancelReason >>> input[type="text"]{
    width: 22em;
}
.uq_deviceCancelForm >>> .formrow {
    grid-template-columns: 17em 1fr;
}
</style>