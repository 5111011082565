<template>
    <div class="ly_form_box uq_deviceConfirm" v-show="!isInProgress">
        <div class="formrow">
            <app-label
                name="brand_name"
                label="ブランド事業者名"
            />
            <p ref="jest-device-brand-name">{{deviceDetail.brand_name.name}}</p>
        </div>
        <div v-if="!isSolar">
            <div
                v-if="isElectricHeatPump"
                class="formrow"
            >
                <app-label
                    name="system_number"
                    label="システム形式"
                />
                <p ref="jest-device-bb-manufacturer-name">{{deviceDetail.system_number}}</p>
            </div>
            <div class="formrow">
                <app-label
                    name="pu_manufacturer_name"
                    :label="puManufacturerName"
                />
                <p ref="jest-device-pu-manufacturer-name">{{deviceDetail.pu_manufacturer_name}}</p>
            </div>
            <div class="formrow">
                <app-label
                    name="pu_number"
                    :label="puNumberName"
                />
                <p ref="jest-device-pu-number">{{deviceDetail.pu_number}}</p>
            </div>
            <div
                v-if="!isElectricHeatPump"
                class="formrow"
            >
                <app-label
                    name="tu_manufacturer_name"
                    label="貯湯ユニット製造事業者名"
                />
                <p ref="jest-device-tu-manufacturer-name">{{deviceDetail.tu_manufacturer_name}}</p>
            </div>
            <div class="formrow">
                <app-label
                    name="tu_number"
                    label="貯湯ユニット品番（貯湯・補助熱源一体型ユニット含む）"
                />
                <p ref="jest-device-tu-number">{{deviceDetail.tu_number}}</p>
            </div>
            <div
                v-if="!isElectricHeatPump"
                class="formrow"
            >
                <app-label
                    name="bb_manufacturer_name"
                    label="補助熱源機製造事業者名"
                />
                <p ref="jest-device-bb-manufacturer-name">{{deviceDetail.bb_manufacturer_name}}</p>
            </div>
            <div
                v-if="!isElectricHeatPump"
                class="formrow"
            >
                <app-label
                    name="bb_number"
                    label="補助熱源機品番"
                />
                <p ref="jest-device-bb-number">{{deviceDetail.bb_number}}</p>
            </div>
        </div>
        <div
            class="formrow"
            v-if="isSolar"
        >
            <app-label
                name="system_type"
                label="システム型式"
            />
            <p ref="jest-device-system-type">{{deviceDetail.system_type}}</p>
        </div>
        <div
            class="formrow"
            v-if="isSolarSystem"
        >
            <app-label
                name="heat_collector_type"
                label="集熱器型式"
            />
            <p ref="jest-device-heat-collector-type">{{deviceDetail.heat_collector_type}}</p>
        </div>
        <div
            class="formrow"
            v-if="isSolarSystem"
        >
            <app-label
                name="heat_storage_tank_type"
                label="蓄熱槽型式"
            />
            <p ref="jest-device-heat-storage-tank-type">{{deviceDetail.heat_storage_tank_type}}</p>
        </div>
        <div class="formrow">
            <app-label
                name="publish_date"
                label="情報公開日"
            />
            <p ref="jest-device-release-date">{{date(deviceDetail.publish_date)}}</p>
        </div>
        <div class="formrow">
            <app-label
                name="release_date"
                label="発売予定年月日"
            />
            <p ref="jest-device-release-date">{{date(deviceDetail.release_date)}}</p>
        </div>
        <div class="formrow">
            <app-label
                name="certificate_number"
                label="成績証明書番号または自己適合宣言書番号（試験品質）"
            />
            <p ref="jest-device-certificate-number">{{deviceDetail.certificate_number}}</p>
        </div>
        <div class="formrow">
            <app-label
                name="note"
                label="備考"
            />
            <p ref="jest-device-note">{{deviceDetail.note}}</p>
        </div>
        <div class="formrow">
            <app-label
                name="string_status"
                label="ステータス"
            />
            <p ref="jest-device-string-status">{{deviceDetail.string_status}}</p>
        </div>
    </div>
</template>

<script>
/**
 * 機器情報の更新時の確認表示用の表
 *
 * [算出プロパティ]
 * date ... 日付文字列を「YYYY年MM月DD日」の形式に成形
 * deviceDetail ... 表示中のページに対応した機器詳細のゲッターを返す
 * 
 * [イベント処理]
 * getDevice ... 表示中のページに対応した機器詳細取得用のアクションを返す
 */
import { mapGetters } from 'vuex'
import moment from 'moment'
import { move } from '@/util'
import Progress from '@/mixins/progress.js'
import device from '@/mixins/device'

export default {
    name:'DeviceConfirm',
    mixins:[Progress, device],
    computed:{
        ...mapGetters({
            cogenerationDetail:'cogenerations/cogenerationDetail',
            hybridWaterHeatersDetail:'hybridWaterHeaters/hybridWaterHeatersDetail',
            electricHeatPumpsDetail:'electricHeatPumps/electricHeatPumpsDetail',
            solarSystemDetail:'solarSystems/solarSystemDetail',
            solarWaterHeaterDetail:'solarWaterHeaters/solarWaterHeaterDetail',
        }),
        /**
         * 日付文字列を「YYYY年MM月DD日」の形式に成形
         */
        date(){
            return function (dateString) {
                return moment(dateString).format('YYYY年MM月DD日')
            }
        },
        /**
         * 表示中のページに対応した機器詳細のゲッターを返す
         */
        deviceDetail(){
            return {
                cogenerations:this.cogenerationDetail,
                hybridWaterHeaters:this.hybridWaterHeatersDetail,
                electricHeatPumps:this.electricHeatPumpsDetail,
                solarSystems:this.solarSystemDetail,
                solarWaterHeaters:this.solarWaterHeaterDetail,
            }[this.$route.meta.deviceCategoryKey]
        },
        puManufacturerName(){
            return {
                cogenerations:'発電ユニット製造事業者名',
                hybridWaterHeaters:'ヒートポンプユニット製造事業者名',
                electricHeatPumps:'製造事業者名',
            }[this.$route.meta.deviceCategoryKey]
        },
        puNumberName(){
            return {
                cogenerations:'発電ユニット番号',
                hybridWaterHeaters:'ヒートポンプユニット番号',
                electricHeatPumps:'ヒートポンプユニット形式',
            }[this.$route.meta.deviceCategoryKey]
        },
        isElectricHeatPump(){
            return this.$route.meta.deviceCategoryKey === 'electricHeatPumps'
        },
        isSolarSystem(){
            return this.$route.meta.deviceCategoryKey === 'solarSystems'
        },
        isSolarWaterHeater(){
            return this.$route.meta.deviceCategoryKey === 'solarWaterHeaters'
        },
        isSolar(){
            return this.isSolarSystem || this.isSolarWaterHeater
        },
    },
    async mounted(){
        const loader = this.$loading.show()
        this.startProgress()
        // 機器詳細データを取得する
        await this.getDevice()
        // データを取得できなかった場合はNot Foundを表示
        if (Object.keys(this.deviceDetail).length == 0) {
            move(this.$router, '/NotFound')
        }
        this.finishProgress()
        loader.hide()
    },
    methods:{
        /**
         * 表示中のページに対応した機器詳細取得用のアクションを返す
         */
        getDevice(){
            return this.$store.dispatch(this.$route.meta.deviceCategoryKey + '/getDeviceDetail', {id:this.$route.params.id, isPublic:false})
        },
    }
}
</script>

<style scoped>
.ly_form_box >>> div.formrow {
    grid-template-columns: 30em minmax(300px,1fr);
    -ms-grid-columns: 30em minmax(300px,1fr);
}
.ly_form_box >>> div.formrow:last-of-type {
    border-bottom:0px;
}
</style>